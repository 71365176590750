import React, { memo, useCallback, useRef } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ProductItem from "../ProductItem/ProductItem"
import { SlideNext, SlidePrev } from '../../svg/svg';
import { Link, useNavigate } from 'react-router-dom';

function ProductGallery(props) {
    const navigate = useNavigate()
    // console.log(props);
    const swiperRef = useRef(null)
    function handleHover(e) {
        if (props.currentProductData.length < 3) {
            return
        }
        if(window.innerWidth > 1440) {
            if(e.currentTarget.previousSibling.classList.contains("swiper-slide-active")) {
                e.currentTarget.previousSibling.classList.remove("swiper-slide-active")
                e.currentTarget.previousSibling.classList.add("swiper-slide-prev-active")
                e.currentTarget.previousSibling.style.marginRight = "160px"
                e.currentTarget.previousSibling.previousSibling.style.marginRight = "160px"
                e.currentTarget.classList.add("swiper-slide-active")
            }
            if(e.currentTarget.nextSibling.classList.contains("swiper-slide-active")) {
                e.currentTarget.nextSibling.classList.remove("swiper-slide-active")
                e.currentTarget.nextSibling.classList.add("swiper-slide-prev-active")
                e.currentTarget.nextSibling.style.marginRight = "80px"
                e.currentTarget.style.marginRight = "160px"
                e.currentTarget.nextSibling.nextSibling.style.marginRight = "80px"
                e.currentTarget.previousSibling.style.marginRight = "340px"
                e.currentTarget.classList.add("swiper-slide-active")
            }
        }else {
            if(e.currentTarget.previousSibling.classList.contains("swiper-slide-active")) {
                e.currentTarget.previousSibling.classList.remove("swiper-slide-active")
                e.currentTarget.previousSibling.classList.add("swiper-slide-prev-active")
                e.currentTarget.previousSibling.style.marginRight = "80px"
                e.currentTarget.previousSibling.previousSibling.style.marginRight = "80px"
                e.currentTarget.classList.add("swiper-slide-active")
            }
            if(e.currentTarget.nextSibling.classList.contains("swiper-slide-active")) {
                e.currentTarget.nextSibling.classList.remove("swiper-slide-active")
                e.currentTarget.nextSibling.classList.add("swiper-slide-prev-active")
                e.currentTarget.nextSibling.style.marginRight = "80px"
                e.currentTarget.nextSibling.nextSibling.style.marginRight = "80px"
                e.currentTarget.previousSibling.style.marginRight = "140px"
                e.currentTarget.classList.add("swiper-slide-active")
            }
        }
    }

    function handlerOut (e) {
        if (props.currentProductData.length < 3) {
            return
        }
        if(window.innerWidth > 1440) {
            if(e.currentTarget.previousSibling.classList.contains("swiper-slide-prev-active")) {
                e.currentTarget.previousSibling.classList.add("swiper-slide-active")
                e.currentTarget.previousSibling.classList.remove("swiper-slide-prev-active")
                e.currentTarget.previousSibling.style.marginRight = "200px"
                e.currentTarget.previousSibling.previousSibling.style.marginRight = "200px"
                e.currentTarget.classList.remove("swiper-slide-active")
            }
            if(e.currentTarget.nextSibling.classList.contains("swiper-slide-prev-active")) {
                e.currentTarget.nextSibling.classList.add("swiper-slide-active")
                e.currentTarget.nextSibling.classList.remove("swiper-slide-prev-active")
                e.currentTarget.nextSibling.style.marginRight = "200px"
                e.currentTarget.nextSibling.nextSibling.style.marginRight = "200px"
                e.currentTarget.previousSibling.style.marginRight = "200px"
                e.currentTarget.classList.remove("swiper-slide-active")
            }
        }else {
            if(e.currentTarget.previousSibling.classList.contains("swiper-slide-prev-active")) {
                e.currentTarget.previousSibling.classList.add("swiper-slide-active")
                e.currentTarget.previousSibling.classList.remove("swiper-slide-prev-active")
                e.currentTarget.previousSibling.style.marginRight = "100px"
                e.currentTarget.previousSibling.previousSibling.style.marginRight = "100px"
                e.currentTarget.classList.remove("swiper-slide-active")
            }
            if(e.currentTarget.nextSibling.classList.contains("swiper-slide-prev-active")) {
                e.currentTarget.nextSibling.classList.add("swiper-slide-active")
                e.currentTarget.nextSibling.classList.remove("swiper-slide-prev-active")
                e.currentTarget.nextSibling.style.marginRight = "100px"
                e.currentTarget.nextSibling.nextSibling.style.marginRight = "100px"
                e.currentTarget.previousSibling.style.marginRight = "100px"
                e.currentTarget.classList.remove("swiper-slide-active")
            }
        }
    }

    const prevSlide = useCallback(() => {
        swiperRef.current?.swiper.slidePrev();
    }, [swiperRef]);

    const nextSlide = useCallback(() => {
        swiperRef.current?.swiper.slideNext();
    }, [swiperRef]);

    return (
        <section id={props.id} className="productGallery">
            <div className="productGallery__container _container">
                <div onClick={() => navigate(props.url)} style={{cursor: 'pointer'}} className="productGallery__title">
                    <h2>{props.title}</h2>
                </div>
                {/* {
                props.currentProductData.length < 3 ? 
                        <div>        
                            {
                                props.currentProductData.map(el => (
                                    <div key={el.id} style={{width: '30%'}}>
                                        <ProductItem adreas={el.address} short={el.short} price={el.price} img={el.poster} href={"/product/" + el.catalog + "/" + el.id} />
                                    </div>
                                ))
                            }
                        </div>
                : */}
                <div className="productGallery__swiper">
                    <div className="swiper-btns">
                        <div>
                            <button onClick={prevSlide} className="slideprev">
                                <SlidePrev />
                            </button>
                            <button onClick={nextSlide} className="slidenext">
                                <SlideNext />
                            </button>
                        </div>
                    </div>
                    {props.currentProductData.length && <Swiper
                        ref={swiperRef}
                        breakpoints={{
                            0: {
                                spaceBetween: 16,
                                centeredSlides: false,
                                centeredSlidesBounds: false,
                                slidesPerView: "auto",
                            },

                            768: {
                                spaceBetween: 90,
                                slidesPerView: 3,
                            },

                            1024: {
                                spaceBetween: 100,
                                slidesPerView: 3,
                            },

                            1440: {
                                spaceBetween: 200,
                                slidesPerView: 3,
                            },
                        }}
                        centeredSlides={true}
                        slidesPerView="auto"
                        loop={true}

                    >
                        {
                            props.currentProductData.map(el => (
                                <SwiperSlide key={el.id} onMouseOver={handleHover} onMouseLeave={handlerOut}>
                                    <ProductItem adreas={el.address} short={el.short} price={el.price} img={el.poster} href={"/product/" + el.catalog + "/" + el.id} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>}
                </div>
                {/* } */}
            </div>
        </section>

    )
}

export default memo(ProductGallery)