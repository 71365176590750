import { Route, Routes, useLocation } from 'react-router-dom';
import HomeWrapper from "./pages/HomeWrapper/HomeWrapper"
import './App.css';
import "./styles.scss"
import Home from './components/Home/Home';
import { useState } from 'react';
import Catalog from './components/Catalog/Catalog';
import IMAGES from './img';
import ProductDetail from './components/ProductDetail/ProductDetail';
import Calculator from './components/Calculator/Calculator';
import { useEffect } from 'react'
import customAxios from './axios'
import axios from 'axios';
import Loader from './components/Loader/Loader';
import { content } from './localization';
import Error from './pages/Error/Error';

const BASEURL = process.env.REACT_APP_BASE_URL

function App() {
  const [leng, setLeng] = useState([
    {
      leng: 'en',
      title: 'ENG',
      active: true
    },
    {
      leng: 'ru',
      title: 'RU',
      active: false
    },
    {
      leng: 'am',
      title: 'ARM',
      active: false
    }
  ])
  const [catalogData, setCatalogData] = useState([
    {
      id: "1",
      url: "",
      title: "New Build",
      slug: "newBuilding",
      img: IMAGES.catalog1
    },
    {
      id: "2",
      url: "/catalog/apartment",
      title: "Apartment",
      slug: "apartment",
      img: IMAGES.catalog2
    },
    {
      id: "3",
      url: "/catalog/commercial",
      title: "Commercial",
      slug: "commercial",
      img: IMAGES.catalog3
    },
    {
      id: "4",
      url: "/catalog/land",
      title: "Land",
      slug: "land",
      img: IMAGES.catalog4
    },
    {
      id: "5",
      url: "/catalog/hous",
      title: "Hous",
      slug: "hous",
      img: IMAGES.catalog5
    },
    
  ])
 
  const [isLoading, setIsLoading] = useState(true)
  const [productsData, setProductsData] = useState([])
  
  const [prevURL, setPrevURL] = useState('')
  const { pathname } = useLocation()

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    changeLeng(userLanguage.slice(0, 2))
  }, [])

  useEffect(() => {
    return () => {
      setPrevURL(pathname)
    }
  }, [pathname])

  useEffect(() => {
    setCatalogData(data => [
      ...data.map((el, idx) => ({
        ...el,
        title: content.catalogDataTitles[idx]
      }))
    ])
  }, [leng])

  useEffect(() => {

    // console.log(content.getLanguage());
      axios.all([
        customAxios.get(`apartmentsview/${leng.find(l => l.active).leng}`),
        customAxios.get(`commercialsview/${leng.find(l => l.active).leng}`),
        customAxios.get(`landsview/${leng.find(l => l.active).leng}`),
        customAxios.get(`homesview/${leng.find(l => l.active).leng}`),
        customAxios.get(`newbuildingsview/${leng.find(l => l.active).leng}`),
      ]).then(axios.spread(({ data: apartmentsData }, { data: commercialsData }, { data: landsData }, { data: homesData }, { data: newbuildingsData }) => {
        const currentData = [
          ...apartmentsData.filter(el => el.images.length && el.is_rent === 0)
            .map(el => ({
              id: el.id.toString(),
              address: el.address + ' , ' + el.code,
              price: el.selling_price + ' $',
              catalog: 'apartment',
              poster: BASEURL + el.images[0].path
            })).slice(0, 12),
          ...commercialsData.filter(el => el.images.length && el.is_rent === 0)
            .map(el => ({
              id: el.id.toString(),
              address: el.address + ' , ' + el.code,
              price: el.selling_price + ' $',
              catalog: 'commercial',
              poster: BASEURL + el.images[0].path
            })).slice(0, 12),
          ...homesData.filter(el => el.images.length && el.is_rent === 0)
            .map(el => ({
              id: el.id.toString(),
              address: el.address + ' , ' + el.code,
              price: el.selling_price + ' $',
              catalog: 'hous',
              poster: BASEURL + el.images[0].path
            })).slice(0, 12),
          ...landsData.map(el => ({
            id: el.id.toString(),
            address: el.address + ' , ' + el.code,
            price: el.selling_price + ' $',
            catalog: 'land',
            poster: IMAGES.placeholder
          })).slice(0, 12),
          ...newbuildingsData.filter(el => el.images.length)
            .map(el => ({
              id: el.id.toString(),
              short: el[`short_description_${leng.find(l => l.active).leng}`],
              // short: el.short_description_en,
              catalog: 'newBuilding',
              poster: BASEURL + el.images[0].path
            }))
        ]
        setProductsData(currentData)
        setCatalogData([...[
          {
            id: "1",
            url: "",
            title: content.catalogDataTitles[0],
            slug: "newBuilding",
            img: IMAGES.catalog1
          },
          {
            id: "2",
            url: "/catalog/apartment",
            title: content.catalogDataTitles[1],
            slug: "apartment",
            img: currentData.find(el => el.catalog === 'apartment').poster
          },
          {
            id: "3",
            url: "/catalog/commercial",
            title: content.catalogDataTitles[2],
            slug: "commercial",
            img: currentData.find(el => el.catalog === 'commercial').poster
          },
          {
            id: "4",
            url: "/catalog/land",
            title: content.catalogDataTitles[3],
            slug: "land",
            img: IMAGES.catalog4
          },
          {
            id: "5",
            url: "/catalog/hous",
            title: content.catalogDataTitles[4],
            slug: "hous",
            img: currentData.find(el => el.catalog === 'hous').poster
          },

        ]])
      }))
      .finally(() => {
        setIsLoading(false)
      })
  }, [leng])

  const changeLeng = (activeLeng) => {
    setLeng(leng => {
      content.setLanguage(activeLeng)
      return leng.map(l => ({
        ...l,
        active: l.leng === activeLeng ? true : false
      }))
    })
  }

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<HomeWrapper {...{ catalogData, changeLeng, leng }} />}>
          <Route index element={isLoading ? <Loader /> : <Home {...{catalogData, productsData}}/>} />
          <Route path='/catalog/:slug' element={<Catalog prevURL={prevURL} catalogData={catalogData} leng={leng.find(el => el.active).leng} />} />
          <Route path='/product/:catalog/:id' element={<ProductDetail {...{productsData, lengs: leng}} />} />
          <Route path='/catalog/calculator' element={<Calculator />} />
        </Route>
        <Route path='*' element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
