import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Thumbs } from "swiper";
import { DollarIcon, LocationIcon, SlideNext, SlidePrev } from '../../svg/svg';
import customAxios from '../../axios';
import IMAGES from '../../img';
import { useMemo } from 'react';
import { content } from '../../localization';
import Fancybox from '../Fancybox/Fancybox';


const BASEURL = process.env.REACT_APP_BASE_URL


function ProductDetail({ lengs }) {
    const { id, catalog } = useParams();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null)
    const [currentVideo, setCurrentVideo] = useState("")

    const [selectedHouse, setSelectedHouse] = useState('')
    const [selectedModal, setSelectedModal] = useState('')
    // const houseRef = useRef(null)
    // const floorRef = useRef(null)
    const modalRef = useRef(null)
    const galleryRef = useRef(null)
    const nbGalleryRef = useRef(null)

    const location = useLocation()
    const navigate = useNavigate()
    // console.log(location.state);

    const leng = useMemo(() => {
        return lengs.find(el => el.active).leng
    }, [lengs])


    useEffect(() => {
        let url
        switch (catalog) {
            case 'land':
                url = `landsview/${id}/${leng}/`
                break;
            case 'commercial':
                url = `commercialsview/${id}/${leng}/`
                break;
            case 'apartment':
                url = `apartmentsview/${id}/${leng}/`
                break;
            case 'hous':
                url = `homesview/${id}/${leng}/`
                break;
            case 'newBuilding':
                url = `newbuildingsview/${id}/${leng}/`
                break;
        }
        // console.log(url);
        customAxios.get(url)
                    .then(({data: [data]}) => {
                        // console.log(data);

                        let currentItem

                        switch (catalog) {
                            case 'newBuilding':
                                currentItem = {
                                    code: data.code,
                                    images: data.images?.length ? data.images.map(img => ({ ...img, path: BASEURL + img.path })) : [{ id: 1, path: IMAGES.placeholder }],
                                    plans: data.plans?.length ? data.plans.map(img => ({ ...img, path: BASEURL + img.path })) : [],
                                    videos: data.videos?.length ? data.videos.map(video => ({ ...video, path: BASEURL + video.path })) : [],
                                    description: data[`description_${leng}`]
                                }
                                setSelectedHouse(currentItem.plans.at(0)?.name.split('__')[1])
                                setSelectedModal(currentItem.plans.at(0)?.path)
                                setCurrentVideo(currentItem.videos.at(0)?.path)
                                break
                            case 'land':
                                currentItem = {
                                    img: data.images?.length ? data.images.map(img => ({ ...img, path: BASEURL + img.path })) : [{ id: 1, path: IMAGES.placeholder }],
                                    address: data.address,
                                    price: data.is_rent ? data.rent_price : data.selling_price,
                                    code: data.code,
                                    productInfo: [
                                        {
                                            id: "1",
                                            title: content.area(data.sum_area)
                                        },
                                        {
                                            id: "3",
                                            title: data.status
                                        },
                                        

                                    ]
                                }
                                break;
                            case 'commercial':
                                currentItem = {
                                    img: data.images?.length ? data.images.map(img => ({ ...img, path: BASEURL + img.path })) : [{ id: 1, path: IMAGES.placeholder }],
                                    address: data.address,
                                    price: data.is_rent ? data.rent_price : data.selling_price,
                                    code: data.code,
                                    productInfo: [
                                        {
                                            id: "1",
                                            title: `${content.floor} ${data.floor}/${data.floors}`
                                        },
                                        {
                                            id: "2",
                                            title: content.hall(data.hall_area)
                                        },
                                        {
                                            id: "3",
                                            title: data.status
                                        },
                                        {
                                            id: "4",
                                            title: data.repair_condition
                                        },
                                        {
                                            id: "5",
                                            title: data.building_type
                                        },
                                        {
                                            id: "6",
                                            title: data.rent_type
                                        },
                                        
                                    ]
                                }
                                break;
                            case 'apartment':
                                currentItem = {
                                    img: data.images?.length ? data.images.map(img => ({ ...img, path: BASEURL + img.path })) : [{ id: 1, path: IMAGES.placeholder }],
                                    address: data.address,
                                    price: data.is_rent ? data.rent_price : data.selling_price,
                                    code: data.code,
                                    productInfo: [
                                        {
                                            id: "1",
                                            title: `${content.floor} ${data.floor}/${data.floors}`
                                        },
                                        {
                                            id: "2",
                                            title: content.area(data.total_area)
                                        },
                                        {
                                            id: "3",
                                            title: `${data.rooms} ${content.rooms}`
                                        },
                                        {
                                            id: "4",
                                            title: data.repair_condition
                                        },
                                        {
                                            id: "5",
                                            title: data.building_type
                                        },
                                        {
                                            id: "6",
                                            title: data.rent_type
                                        }
                                    ]
                                }
                                break;
                            case 'hous':
                                currentItem = {
                                    img: data.images?.length ? data.images.map(img => ({ ...img, path: BASEURL + img.path })) : [{ id: 1, path: IMAGES.placeholder }],
                                    address: data.address,
                                    price: data.is_rent ? data.rent_price : data.selling_price,
                                    code: data.code,
                                    productInfo: [
                                        {
                                            id: "1",
                                            title: `${data.floors} ${content.floors}`
                                        },
                                        {
                                            id: "2",
                                            title: content.houseArea(data.house_area)
                                        },
                                        {
                                            id: "3",
                                            title: `${data.rooms} ${content.rooms}`
                                        },
                                        {
                                            id: "4",
                                            title: data.repair_condition
                                        },
                                        {
                                            id: "5",
                                            title: data.status
                                        },
                                        {
                                            id: "6",
                                            title: data.rent_type
                                        },
                                        {
                                            id: "7",
                                            title: !!data.garage && content.garage + data.garage
                                        },
                                        {
                                            id: "8",
                                            title: !!data.basement && content.basement + data.basement
                                        },
                                        {
                                            id: "9",
                                            title: content.landArea(data.land_area)
                                        },
                                    ]
                                }
                                break;
                        }
                        // console.log(currentItem);
                        
                        setCurrentProduct(currentItem)
                    })
                    .catch(() => {
                        navigate('/error404')
                    })
    }, [catalog, id, leng])

    // useEffect(() => {
    //     if (selectedModal) {
    //         modalRef.current.click()
    //     }
    // }, [selectedModal])

    useEffect(() => {
        window.scrollTo(0, 0)
        
    }, [])



    const gallerySlide = useRef(null)
    const thumbsSlide = useRef(null)

    const prevSlide = useCallback(() => {
        gallerySlide.current?.swiper.slidePrev();
        thumbsSlide.current?.swiper.slidePrev();
    }, [gallerySlide, thumbsSlide]);

    const nextSlide = useCallback(() => {
        gallerySlide.current?.swiper.slideNext();
        thumbsSlide.current?.swiper.slideNext();
    }, [gallerySlide, thumbsSlide]);

    const videochange = useCallback((src) => {
        // console.log(src);
        setCurrentVideo(src)
    }, [])


    // const openPlan = () => {
    //     console.log(floorRef.current.value);
    //     setSelectedModal(floorRef.current.value)
    // }
    return (
        <section className="product-detail">
            
            <div className="product-detail__container _container">
                <div className="product-detail__title">
                    <button onClick={() => navigate(location.state.slug ? `/catalog/${location.state.slug}` : '/', { state: { slug: location.state.slug}})}>{content.back}</button>
                    <h1> {content.code}: {currentProduct?.code}</h1>
                </div>
                {
                    catalog === "newBuilding" ? (
                        <div className='product-detail__newBuilding'>
                            <div className="product-detail__swiper">
                                {
                                currentProduct &&
                                <>
                                    <Swiper ref={gallerySlide} className='swiper-gallery'
                                        spaceBetween={10}
                                        thumbs={{ swiper: thumbsSwiper }}
                                        modules={[FreeMode, Thumbs]}
                                        loop={true}
                                    >
                                        {
                                            currentProduct?.images.map((el, idx) => (
                                                <SwiperSlide key={el.id} >
                                                    {/* <Fancybox
                                                        options={{
                                                            Carousel: {
                                                                infinite: false,
                                                            },
                                                        }}
                                                    >
                                                        <a data-fancybox="gallery" href={el.path}> */}
                                                            <img 
                                                                src={el.path} 
                                                                alt='img' 
                                                                onClick={() => {
                                                                    nbGalleryRef.current.lastElementChild.children[idx].click()
                                                                }}
                                                            />
                                                        {/* </a>

                                                    </Fancybox> */}
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                    <Swiper ref={thumbsSlide} className='swiper-thumbs'
                                        loop={false}
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={24}
                                        slidesPerView="auto"
                                        watchSlidesProgress={true}
                                        modules={[Thumbs]}
                                    >
                                        {
                                            currentProduct?.images.map(el => (
                                                <SwiperSlide key={el.id} >
                                                    <img src={el.path} alt='img' />
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </>
                                }
                                <div className='swiper-btn'>
                                    <button onClick={prevSlide} className='slide-prev'>
                                        <SlidePrev />
                                    </button>
                                    <button onClick={nextSlide} className='slide-next'>
                                        <SlideNext />
                                    </button>
                                </div>
                            </div>
                            {
                                !!Array.from(new Set(currentProduct?.plans.map(plan => plan.name.split('__')[1]))).length &&
                                <>
                                    {/* <div style={{ marginBottom: 0 }} className='box productDetail-selects '>
                                        <span>{content.buildingSelect}</span>
                                        <span>{content.floorSelect}</span>
                                    </div> */}
                                    <div className='box productDetail-selects '>
                                        <select value={selectedHouse} onChange={(e) => { setSelectedHouse(e.target.value); setSelectedModal(currentProduct?.plans.find(plan => plan.name.split('__')[1] === e.target.value).path)}}>
                                            {
                                                Array.from(new Set(currentProduct?.plans.map(plan => plan.name.split('__')[1])))
                                                    .map(plan => (
                                                        <option value={plan} key={Math.random()}>{plan}</option>
                                                    ))
                                            }
                                        </select>
                                        <select value={selectedModal} onChange={(e) => setSelectedModal(e.target.value)} >
                                            {
                                                [...currentProduct?.plans.filter(plan => plan.name.split('__')[1] === selectedHouse)]
                                                .sort((a, b) => +a.name.split('__')[2] - +b.name.split('__')[2])
                                                .map(plan => (
                                                    <option value={plan.path} key={plan.id}>{plan.name.split('__')[2]}</option>
                                                ))
                                            }
                                        </select>
                                        <button>
                                            <Fancybox
                                                options={{
                                                    Carousel: {
                                                        infinite: false,
                                                    },
                                                }}
                                                >
                                                <a style={{display: 'inline-block', width: '100%', height: '100%'}} ref={modalRef} data-fancybox="gallery" href={selectedModal}>
                                                    {content.seePlan}
                                                    <img width={0} height={0} src={selectedModal} alt='img' />
                                                </a>

                                            </Fancybox>
                                        </button>

                                        <div className='planModal'  >
                                            
                                        </div>
                                    </div>
                                </>
                            }
                            <div className='product-detail__paragraph'>
                                {/* <p> */}
                                    {
                                        leng === 'am' ? 
                                            currentProduct?.description?.split('։').map((el, idx) => !!el.trim().length && <p key={idx}>{el}։</p>)
                                        : leng === 'ru' ?
                                            currentProduct?.description?.split('.').map((el, idx) => !!el.trim().length && <p key={idx}>{el}.</p>)
                                        :
                                            currentProduct?.description?.split('.').map((el, idx) => !!el.trim().length && <p key={idx}>{el}.</p>)

                                    }
                                {/* </p> */}
                            </div>
                            <div className='product-detail__video'>
                                
                                {/* <Video url={currentVideo} /> */}

                                {currentVideo && <video width={'100%'} src={currentVideo} controls></video>}
                                <div className='product-detail__video-grid'>
                                    {
                                        currentProduct?.videos.map(video => (
                                            <video style={{cursor: 'pointer'}} width={'100%'} key={video.id} onClick={() => videochange(video.path)} src={video.path} ></video>
                                            // <VideoItem key={video.id} src={video.img} click={() => videochange(video.path)} />
                                        ))
                                    }
                                </div>
                                <div ref={nbGalleryRef} style={{display: 'none'}}>
                                    <Fancybox>
                                    {
                                        currentProduct?.images.map(el => (
                                                        <a data-fancybox="gallery" href={el.path}> 
                                                <img src={el.path} alt='img' />
                                                </a>

                                        ))
                                    }
                                    </Fancybox> 
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="product-detail__flex">
                            {currentProduct && <div className="product-detail__swiper">
                                
                                <Swiper ref={gallerySlide} className='swiper-gallery'
                                    spaceBetween={10}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[FreeMode, Thumbs]}
                                    loop={true}
                                >
                                    {
                                        currentProduct?.img.map((el, idx) => (
                                            
                                            <SwiperSlide key={el.id} >
                                                {/* <Fancybox
                                                    options={{
                                                        Carousel: {
                                                            infinite: false,
                                                        },
                                                    }}
                                                >
                                                    <a data-fancybox="gallery" href={el.path}> */}
                                                        <img 
                                                            src={el.path} 
                                                            alt='img' 
                                                            onClick={() => {
                                                                galleryRef.current.lastElementChild.children[idx].click()
                                                            }}
                                                        />
                                                    {/* </a> */}
                                                    
                                                {/* </Fancybox> */}
                                            </SwiperSlide>

                                        ))
                                    }
                                    
                                </Swiper>
                                {currentProduct?.img.length > 1 && <>
                                    <Swiper ref={thumbsSlide} className='swiper-thumbs'
                                        loop={true}
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={24}
                                        slidesPerView="auto"
                                        breakpoints={{
                                            0: {
                                                spaceBetween: 16,
                                                direction: "horizontal"
                                            },

                                            768: {
                                                direction: "vertical",
                                                spaceBetween: 18
                                            },

                                            1024: {
                                                direction: "vertical",
                                                spaceBetween: 18
                                            },
                                            1440: {
                                                direction: "vertical",
                                                spaceBetween: 24
                                            },
                                        }}
                                        watchSlidesProgress={true}
                                        modules={[Thumbs]}
                                    >
                                        {
                                            currentProduct?.img.map(el => (
                                                <SwiperSlide key={el.id} >
                                                    <img src={el.path} alt='img' />
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                    <div className='swiper-btn'>
                                        <button onClick={prevSlide} className='slide-prev'>
                                            <SlidePrev />
                                        </button>
                                        <button onClick={nextSlide} className='slide-next'>
                                            <SlideNext />
                                        </button>
                                    </div>
                                </>}
                            </div>} 
                            <div className="product-detail__info">
                                <div className="product-detail__conatcts">
                                    <p>
                                        <LocationIcon />
                                        {currentProduct?.address}
                                    </p>
                                    <p>
                                        <DollarIcon />
                                        {currentProduct?.price}
                                    </p>
                                </div>
                                <div className="product-detail__info">
                                    {
                                        currentProduct?.productInfo.map(el => el.title && (
                                            <p key={el.id}>
                                                <span />{el.title}
                                            </p>
                                        ))
                                    }
                                </div>
                                <div style={{display: 'none'}} ref={galleryRef}>
                                    <Fancybox
                                        options={{
                                            Carousel: {
                                                infinite: true,
                                            },
                                        }}
                                    >
                                    {
                                        currentProduct?.img.map(el => (
                                                <a data-caption={el.id} data-fancybox="gallery" href={el.path}>
                                                    <img width={50} src={el.path} alt='img' />
                                                </a>

                                            ))
                                    }
                                    </Fancybox>
                                </div>
                            </div>
                        </div>
                    )
                }

            </div >
        </section >

    )
}

export default ProductDetail