const IMAGES = {
    footerIMG: require('./Mask group.png'),
    product1: require("./8fe1e49233510764d2ac766fddcd40d5 (1).png"),
    product2: require("./004acf0eb7832453d59458f0dbfc0411 (1).png"),
    product3: require("./004acf0eb7832453d59458f0dbfc0411.png"),
    product4: require("./8f454a187557b0485929563c318e7093.png"),
    product5: require("./8fe1e49233510764d2ac766fddcd40d5.png"),
    product6: require("./d545cde665efc1a90a0758d51cb341de 1.png"),
    catalog1: require("./8fe1e49233510764d2ac766fddcd40d5.png"),
    catalog2: require("./8f454a187557b0485929563c318e7093.png"),
    catalog3: require("./c3f60f6baf388e6fe01625b127bda71a.png"),
    catalog4: require("./004acf0eb7832453d59458f0dbfc0411.png"),
    catalog5: require("./spacejoy-XpbtQfr9Skg-unsplash 1.png"),
    placeholder: require("./placeholder.jpg"),
    logo: require("./logo.png"),
    slides: [
        require('./slide1.jpg'),
        require('./slide2.jpg'),
        require('./slide3.jpg'),
        require('./slide4.jpg'),
        require('./slide5.jpg')
    ]
}

export default IMAGES