import React from 'react'
import { Link } from 'react-router-dom'
import MainItem from "../MainItem/MainItem"
import { content } from '../../localization'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import IMAGES from '../../img';

function Main({catalogData}) {
    const handleClick = (e) => {
        e.preventDefault()
        document.documentElement.scrollTop = window.innerHeight

    }
    return (
        <section className="main">
            <div className="main__container _container">
                <div className="main__text">
                    {/* <>
                        <h1>WELCOME TO US</h1>
                        <p>Welcome to  <span> Melkonyans reality, </span> here you can find <span> everything you want about houses. </span> on our website you will see the best apartments in Armenia, both for rent and for purchase. we also have other spaces․</p>
                        <a>See Houses</a>
                    </> */}
                    {content.mainJSX(handleClick)}
                </div>
                <Swiper 
                    loop={true} 
                    spaceBetween={24} 
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className='main__swiper'
                >
                    {/* {
                        catalogData.filter(el => el.slug === 'apartment' || el.slug === 'commercial' || el.slug === 'hous').map(el => (
                            <SwiperSlide key={el.id} >
                                <img src={el.img} />
                            </SwiperSlide>
                        ))
                    } */}
                    {
                        IMAGES.slides.map((img, idx) => (
                            <SwiperSlide key={idx} >
                                <img src={img} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </section>

    )
}

export default Main