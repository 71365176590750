import LocalizedStrings from 'react-localization';

export const content = new LocalizedStrings({
    en: {

        test: 'en',
        catalogDataTitles: [
            "New Build",
            "Apartment",
            "Commercial",
            "Land",
            "House",
            "Mortgage calculator"
        ],
        mainJSX: (handleClick) => (
            <>
                <h1>Apartments from the constructor</h1>
                <a className='phone' href='tel:010224345'>010-224345</a>
                <a className='phone' href='tel:077541214'>077-541214</a>
                <a className='phone' href='tel:096541212'>096-541212</a>
                <a href='' onClick={handleClick} className="main-btn" >New Build</a>
            </>
        ),
        address: "Kievyan 5 Yerevan, Armenia",
        calculatorTitle: "Mortgage Calculator",
        type1: "Annuity",
        type2: "Differentiated",
        homePrice: "Home price",
        deposit: "Deposit",
        loanAmount: "Loan amount",
        percent: "Loan percentage %",
        term: "Terms of Loan (year)",
        calculatorBtn1: "Calculate",
        calculatorBtn2: "View the Loan Repayment Schedule",
        calculatorResult1: "Monthly Payment",
        calculatorResult2: "Total Payment",
        calculatorResult3: "Percent",
        th1: "Month",
        th2: "Credit Balance",
        th3: "Payable Interest",
        th4: "Loan amount",
        th5: "Total Payment",  
        filterTitle1: "Prise :", 
        filterTitle2: "Rooms :", 
        filterTitle3: "Time :", 
        filterTitle4: "Zone :", 
        filterTitle5: "Street :", 
        filterTitle6: "Type :", 
        filterTitle7: "Repair Condition :",
        timeArr: ["Sale","Short Term Rent", "Daily", "Long Term Rent"],
        commercialSectionInfo: [ "Store", "Office", "Production", "Restaurant", "Others" ],
        landSectionInfo: [ "Public land area", "Business land area", "Agriculture land area" ],
        housSectionInfo: ["House", "Mansion", "Country house"],
        zonesInfo: ['Center', 'Arabkir', 'Kanaker-Zeytun', 'Ajapnyak', 'Davtashen', 'Nork Marash', 'Shengavit', 'Nor Nork 1-4 Massive', 'Nor Nork 5-6 Massive', 'Nor Nork 7-9 Massive', 'Avan', 'Avan Arinj', 'Erebuni', 'Malatia-Sebastia', 'Regions'],
        area: (n) => "Area " + n + " m²",
        landArea: (n) => "Land area " + n + " m²",
        houseArea: (n) => "House area " + n + " m²",
        hall: (n) => "Hall " + n + " m²",
        floor: "Floor",
        floors: "floors",
        rooms: "room",
        garage: "Garage ",
        basement: "Basement ",
        back: 'Back',
        seePlan: "View",
        code: "Code",
        buildingSelect: "Building",
        floorSelect: "Floor"
    },
    ru: {
        test: 'ru',
        catalogDataTitles: [
            "Новостройка",
            "Квартира",
            "Коммерческая",
            "Земля",
            "Дом",
            "Ипотечный калькулятор"
        ],
        mainJSX: (handleClick) => (
            <>
                <h1>Квартиры от застройщика</h1>
                <a className='phone' href='tel:010224345'>010-224345</a>
                <a className='phone' href='tel:077541214'>077-541214</a>
                <a className='phone' href='tel:096541212'>096-541212</a>
                <a href='' onClick={handleClick} className="main-btn">Новостройки</a>
            </>
        ),
        address: "Киевян 5 Ереван, Армения",
        calculatorTitle: "Ипотечный Калькулятор",
        type1: "Аннуитетный",
        type2: "Дифференцированный",
        homePrice: "Стоимость дома",
        deposit: "Депозит",
        loanAmount: "Величина займа",
        percent: "Процент кредита %",
        term: "Срок кредита (год)",
        calculatorBtn1: "Вычислять",
        calculatorBtn2: "Посмотреть график погашения кредита",
        calculatorResult1: "Ежемесячный платеж",
        calculatorResult2: "Всего к оплате",
        calculatorResult3: "Процент",
        th1: "Месяц",
        th2: "Кредитный баланс",
        th3: "Проценты к уплате",
        th4: "Сумма кредита",
        th5: "Всего к оплате",
        filterTitle1: "Цена :",
        filterTitle2: "Комнатная :",
        filterTitle3: "Время :",
        filterTitle4: "Зона :",
        filterTitle5: "Адрес :",
        filterTitle6: "Типы :",
        filterTitle7: "Состояние :",
        timeArr: ["Продажа", "Краткосрочная аренда", "Суточная аренда", "Долгосрочная аренда"],
        commercialSectionInfo: ["Магазин", "Офис", "Производство", "Ресторан", "Другие"],
        landSectionInfo: ["Жилстрой", "Коммерческое", "Сельхоз"],
        housSectionInfo: ["Дом", "Особняк", "Дача"],
        zonesInfo: ['Центр', 'Арабкир', 'Канакер-Зейтун', 'Ачапняк', 'Давташен', 'Норк Мараш', 'Шенгавит', '1-4 Н.М.', '5-6 Н.М.', '7-9 Н.М.', 'Аван', 'Аван Ариндж', 'Эребуни', 'Малатия-Себастия', 'Районы'],
        area: (n) => "Площадь " + n + " м²",
        landArea: (n) => "Площадь земли " + n + " м²",
        houseArea: (n) => "Площадь здания " + n + " м²",
        hall: (n) => "Зал " + n + " м²",
        floor: "Этаж",
        floors: "этажный",
        rooms: "комнатная",
        garage: "Гараж ",
        basement: "Подвал ",
        back: 'Назад',
        seePlan: "Посмотреть",
        code: "Код",
        buildingSelect: "Здание",
        floorSelect: "Этаж"
    },  
    am: {
        test: 'am',
        catalogDataTitles: [
            "Նորակառույց",
            "Բնակարան",
            "Կոմերցիոն",
            "Հողատարածք",
            "Տուն",
            "Հիփոթեքային հաշվիչ"
        ],
        mainJSX: (handleClick) => (
            <>
                <h1>Բնակարաններ կառուցապատողից</h1>
                <a className='phone' href='tel:010224345'>010-224345</a>
                <a className='phone' href='tel:077541214'>077-541214</a>
                <a className='phone' href='tel:096541212'>096-541212</a>
                <a href='' onClick={handleClick} style={{ cursor: 'pointer' }} className="main-btn">Նորակառույցներ</a>
            </>
        ),
        address: "Կիևյան 5 Երևան, Հայաստան",
        calculatorTitle: "Հիպոտեկային հաշվիչ",
        type1: "Անուիտետային", 
        type2: "Դիֆերենցված", 
        homePrice: "Տան գինը",
        deposit: "Կանխավճար",
        loanAmount: "Վարկի Գումար",
        percent: "Վարկի տոկոս %",
        term: "Վարկի ժամկետ (տարի)",
        calculatorBtn1: "Հաշվել",
        calculatorBtn2: "Դիտեք վարկի մարման ժամանակացույցը",
        calculatorResult1: "Ամսական վճարում",
        calculatorResult2: "Ընդհանուր վճարում",
        calculatorResult3: "Տոկոսագումար",
        th1: "ԱՄԻՍ",
        th2: "ՎԱՐԿԻ ՄՆԱՑՈՐԴ",
        th3: "ՎՃԱՐՎՈՂ ՏՈԿՈՍԱԳՈՒՄԱՐ",
        th4: "ՎԱՐԿԻ ԳՈՒՄԱՐ",
        th5: "ԸՆԴՀԱՆՈՒՐ ՎՃԱՐՈՒՄ",
        filterTitle1: "Գին :",
        filterTitle2: "Սենյակներ :",
        filterTitle3: "Ժամանակը :",
        filterTitle4: "Շրջան :",
        filterTitle5: "Հասցե :",
        filterTitle6: "Շինության տես. :",
        filterTitle7: "Վիճակ :",
        timeArr: ["Վաճառք", "Կարճ. վարձ.", "Օրավարձով", "Երկար. վարձ."],
        commercialSectionInfo: ["Խանութ", "Գրասենյակ", "Արտադրություն", "Ռեստորան", "Այլ"],
        landSectionInfo: ["Հասարակական նշանակության տարածք", "Կոմերցիոն նշանակության տարածք", "Գյուղատնտեսական նշանակության տարածք"],
        housSectionInfo: ["Տուն", "Առանձնատուն", "Ամառանոց"],
        zonesInfo: ['Կենտրոն', 'Արաբկիր', 'Քանաքեռ-Զեյթուն', 'Աջափնյակ', 'Դավթաշեն', 'Նորք Մարաշ', 'Շենգավիթ', 'Նոր Նորքի 1-4 զ.', 'Նոր Նորքի 5-6 զ.', 'Նոր Նորքի 7-9 զ.', 'Ավան', 'Ավան Առինջ', 'Էրեբունի', 'Մալաթիա-Սեբաստիա', 'Մարզեր'],
        area: (n) => "Մակերես " + n + " մ²",
        landArea: (n) => "Հողի մակ. " + n + " մ²",
        houseArea: (n) => "Շինության մակ. " + n + " մ²",
        hall: (n) => "Սրահ  " + n + " մ²",
        floor: "Հարկ",
        floors: "հարկանի",
        rooms: "սենյականոց",
        garage: "Ավտոտնակ ",
        basement: "Նկուղ ",
        back: 'Ետ',
        seePlan: "Դիտել",
        code: "Կոդ",
        buildingSelect: "Մասնաշենք",
        floorSelect: "Հարկ"
    }
});