import React from 'react'
import { Link } from 'react-router-dom'
import IMAGES from '../../img'
import NavItem from '../NavItem/NavItem'
import { content } from '../../localization'
import { Viber } from '../../svg/svg'

function Footer({catalogData}) {
    return (
        <footer className="footer">
            <div className="footer__img">
                <img src={IMAGES.footerIMG} alt="img" />
            </div>
            <div className="footer__bottom">
                <div className="footer__container _container">
                    <div className="footer__item">
                        <Link to="/" className='logo'>
                            <div style={{ backgroundImage: `url(${IMAGES.logo})`, backgroundPosition: 'center', backgroundSize: 'cover', width: '180px', height: '80px' }}></div>
                        </Link>
                    </div>
                    <div className="footer__item">
                        <ul>
                            {
                                catalogData.filter(el => el.id !== '1').map(el => (
                                    <NavItem key={el.id} href={el.slug} title={el.title} />
                                ))
                            }
                        </ul>
                    </div>
                    <div className="footer__item">
                        <ul>
                            <li><a href='https://www.instagram.com/melqonyan.realty/' target='_blank'>Instagram</a></li>
                            <li><a href='https://www.facebook.com/MelqonyanRealty/' target='_blank'>Facebook</a></li>
                            <li><a href='mailto:melqonyan_realty@mail.ru' className="mailadreas">melqonyan_realty@mail.ru</a></li>
                            <li><a href='tel:+37410224345'>(010) 224345</a></li>
                            <li><a href='tel:+37477541214'>(077) 541214</a> <Viber /></li>
                        </ul>
                    </div>
                    <div className="footer__item">
                        <ul>
                            <li><a href='https://goo.gl/maps/aF5J3M93q5WWwEVs6' target='_blank'>{content.address}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer