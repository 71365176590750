import React, { useEffect, useRef, useState } from 'react'
import { MultiSelect } from '../MultiSelect/MulitiSelect';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import customAxios from '../../axios';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { content } from '../../localization';

function Fillter({ filterData, setFilterData, streets, setStreets, leng, active, setActive }) {
    const [buildingTypes, setBuildingTypes] = useState([])
    const [repairconditions, setRepairconditions] = useState([])
    const [zones, setZones] = useState([
        {
            id: 1,
            value: 'Կենտրոն',
            zoneIdes: [1.1, 1.2, 1.3]
        },
        {
            id: 2,
            value: 'Արաբկիր',
            zoneIdes: [2.1, 2.2, 2.3]
        },
        {
            id: 3,
            value: 'Քանաքեռ-Զեյթուն',
            zoneIdes: [3.1, 3.2]
        },
        {
            id: 4,
            value: 'Աջափնյակ',
            zoneIdes: [4.1, 4.2]
        },
        {
            id: 5,
            value: 'Դավթաշեն',
            zoneIdes: [4.3]
        },
        {
            id: 6,
            value: 'Նորք Մարաշ',
            zoneIdes: [5.1]
        },
        {
            id: 7,
            value: 'Շենգավիթ',
            zoneIdes: [6.1, 6.2, 6.3]
        },
        {
            id: 9,
            value: 'Նոր Նորքի 1-4 զ.',
            zoneIdes: [7.1]
        },
        {
            id: 10,
            value: 'Նոր Նորքի 5-6 զ.',
            zoneIdes: [7.2]
        },
        {
            id: 11,
            value: 'Նոր Նորքի 7-9 զ.',
            zoneIdes: [7.3]
        },
        {
            id: 12,
            value: 'Ավան',
            zoneIdes: [7.4]
        },
        {
            id: 13,
            value: 'Ավան Առինջ',
            zoneIdes: [7.5]
        },
        {
            id: 14,
            value: 'Էրեբունի',
            zoneIdes: [8.1, 8.2]
        },
        {
            id: 15,
            value: 'Մալաթիա-Սեբաստիա',
            zoneIdes: [9.1, 9.2]
        },
        {
            id: 16,
            value: 'Մարզեր',
            zoneIdes: [10]
        },

    ])
    const div = useRef(null)


    const { slug } = useParams();

    

 

    useEffect(() => {
        setZones([...zones.map((el, idx) => ({
            ...el, 
            value: content.zonesInfo[idx]
        }))])
        customAxios.get(`/buildingtypes/${leng}`)
                    .then(({data}) => {
                        setBuildingTypes(data);
                    })
        customAxios.get(`/repairconditions/${leng}`)
                    .then(({data}) => {
                        setRepairconditions(data);
                    })
        customAxios.get('/streets')
                    .then(({data}) => {
                        setStreets(data.map(el => ({
                            id: el.id,
                            zone: el.zone,
                            name: el[`name_${leng}`]
                        })));
                    })
    }, [leng])

    const handlePrice = (value) => {
        setFilterData({
            ...filterData,
            price: {
                min: value[0],
                max: value[1]
            }
        })
    }

    const setPriceMin = (e) => {
        setFilterData({
            ...filterData,
            price: {
                min: e.target.value,
                max: filterData.price.max
            }
        })
    }

    const setPriceMax = (e) => {
        setFilterData({
            ...filterData,
            price: {
                min: filterData.price.min,
                max: e.target.value
            }
        })
    }

    const roomsChange = (id) => {
        setFilterData({
            ...filterData,
            rooms: [
                ...filterData.rooms.map(el => {
                    if(el.id !== id) {
                        return el
                    }else {
                        return {
                            ...el,
                            checked: !el.checked
                        }
                    }
                })
            ]
        })
    }

    const timesChange = (id) => {
        setFilterData({
            ...filterData,
            time: [
                ...filterData.time.map(el => {
                    if(el.id !== id) {
                        return {
                            ...el,
                            checked: false
                        }
                    }else {
                        return {
                            ...el,
                            checked: true
                        }
                    }
                })
            ]
        })
    }


    const currentStreets = useMemo(() => {
        return streets.filter(street => filterData.zone.some(zone => zone.zoneIdes.some(id => id === street.zone)))
    }, [streets, filterData.zone])
    return (
        <>
        <div    
            style={{
                position: 'absolute',
                width: '100vw',
                height: '150vh',
                top: '-50vh',
                left: 0,
                zIndex: 4,
                display: active ? 'block' : 'none'
            }}
            onClick={() => setActive(false)}
        ></div>
        <div  className={active ? "active catalog__fillter" : "catalog__fillter"} >
            <form className="catalog__fillter-box">
                <div className="catalog__fillter-item item-range-price">
                    <p>{content.filterTitle1}</p>
                    <div className="price__box">
                        <div className="range-input">
                            <Slider range
                                min={0}
                                max={10000000}
                                value={[filterData.price.min, filterData.price.max]}
                                // defaultValue={[0, 20000]}
                                onChange={handlePrice}
                                tipFormatter={(value) => `${value}!`} />
                        </div>
                        <div className="price-input">
                            <input type="number" onChange={setPriceMin} value={filterData.price.min} className="range-result" />
                            <input type="number" onChange={setPriceMax} value={filterData.price.max} className="range-result" />
                        </div>
                    </div>
                </div>
                <div className="catalog__fillter-item item-checkboxes">
                    {slug === 'apartment' && <p>{content.filterTitle2}</p>}
                    <div className="flex">
                        {filterData.rooms.map(el => (
                            <label className="checkbox" key={el.id}>
                                <input type="checkbox" checked={el.checked} onChange={() => roomsChange(el.id)} />
                                <p>{el.value}</p>
                                <span />
                            </label>
                        ))}
                    </div>
                </div>
                <div className="catalog__fillter-item item-checkboxes">
                    <p>{content.filterTitle3}</p>
                    <div className="flex time__flex">
                        {filterData.time.map(el => (
                            <label className="checkbox" key={el.id} >
                                <input type="radio" name='time' checked={el.checked} onChange={() => timesChange(el.id)} />
                                <p>{el.value}</p>
                                <span />
                            </label>
                        ))}
                    </div>
                </div>
                <MultiSelect label={content.filterTitle4} options={zones}
                    displayValue={"value"}
                    selectedValues={filterData.zone}
                    toggleList={(newArr) => setFilterData({ ...filterData, zone: [...newArr] })}
                />
                <MultiSelect label={content.filterTitle5} options={currentStreets}
                    displayValue={"name"}
                    selectedValues={filterData.street}
                    toggleList={(newArr) => setFilterData({ ...filterData, street: [...newArr] })} 
                />
                
                {
                    slug !== 'land' &&
                    <>
                        <MultiSelect label={content.filterTitle6} options={buildingTypes}
                            displayValue={"name"}
                            selectedValues={filterData.type}
                            // toggleList={(newArr) => console.log(newArr)} 
                            toggleList={(newArr) => setFilterData(filterData => ({ ...filterData, type: [...newArr] }))} 
                        />
                        <MultiSelect label={content.filterTitle7} options={repairconditions}
                            displayValue={"name"}
                            selectedValues={filterData.repairCondition}
                            toggleList={(newArr) => setFilterData({ ...filterData, repairCondition: [...newArr] })} 
                        />
                    </>
                }
            </form>
        </div>
        </>
    )
}

export default Fillter