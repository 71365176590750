import Multiselect from 'multiselect-react-dropdown'
import React from 'react'
import { InputArrov } from '../../svg/svg'

export class MultiSelect extends React.Component {
    render() {
        return <div className="catalog__fillter-item item-inputs">
            <p>{this.props.label}</p>
            <label>
                <InputArrov />
                <Multiselect
                    isObject={true}
                    selectedValues={this.props.selectedValues}
                    displayValue={this.props.displayValue}
                    style={{
                        chips: {
                            background: '#11101d',
                            'borderRadius': '5px'
                        },
                        multiselectContainer: {
                            color: '#11101d',
                        },
                        searchBox: {
                            border: 'none',
                            'borderBottom': 'unset',
                            'borderRadius': '0px'
                        }
                    }}
                    placeholder=''
                    onKeyPressFn={function noRefCheck() { }}
                    onRemove={(selectedList) => this.props.toggleList(selectedList)}
                    onSearch={function noRefCheck() { }}
                    onSelect={(selectedList) => this.props.toggleList(selectedList)}
                    options={this.props.options}
                />
            </label>
        </div>
    }
}