import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { BuildingIcon, DollarIcon, LocationIcon } from '../../svg/svg'

function ProductItem(props) {
    // console.log(props.img);
    const params = useParams()
    return (
        <div className="product-item">
            <Link onClick={props.saveInfo} to={props.href} state={{ ...params }} className="product-item__img">
                <img src={props.img} alt="" />
            </Link>
            <div className="product-item__text">
                {
                props.adreas && props.price &&
                <>
                    <p>
                        <span className="icon">
                            <LocationIcon />
                        </span>
                        {props.adreas}
                    </p>
                    <p>
                        <span className="icon">
                            <DollarIcon />
                        </span>
                        {props.price}
                    </p>
                </>
                }
                {
                    props.short &&
                    <p>
                        <span className="icon">
                            <BuildingIcon />
                        </span>
                        {props.short}
                    </p>
                }
            </div>
        </div>

    )
}

export default ProductItem