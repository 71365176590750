import React from 'react'
import Main from '../Main/Main'
import ProductGallery from '../ProductGallery/ProductGallery'
import { useState } from 'react'


function Home({catalogData, productsData}) {


    return (
        <>
            <Main {...{catalogData}} />
            {
                catalogData.map(el => (
                    <ProductGallery slug={el.slug} url={el.url} id={el.id} key={el.id} title={el.title} currentProductData={productsData.filter(product => product.catalog === el.slug)}/>
                ))
            }
        </>
    )
}

export default Home