import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

function HomeWrapper({ catalogData, changeLeng, leng }) {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <div className='wrapper'>
            <Header {...{ catalogData, changeLeng, leng }} />
            <main>
                <Outlet />
            </main>
            <Footer {...{catalogData}}/>
        </div>
    )
}

export default HomeWrapper