import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import NavItem from "../NavItem/NavItem"
import { content } from '../../localization'
import IMAGES from '../../img'

function Header({ catalogData, changeLeng, leng }) {
    const [showLang, setShowLang] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    useEffect(() => {
        window.addEventListener('click', handleClick)
        return () => {
            window.removeEventListener('click', handleClick)
        }
    }, [])

    const handleClick = (e) => {
        if (!e.target.classList.contains('ignore')) {
            setShowMenu(false)
            
        }
    }

    return (
        <header className="header">
            <div className="header__container _container">
                <div className="header__flex">
                    <Link className='logo' to="/">
                        <img src={IMAGES.logo} />
                    </Link>
                    <div className="header__right">
                        <button onClick={() => setShowMenu(!showMenu)} className="burger-btn ignore">
                            <span className="ignore" />
                            <span className="ignore" />
                            <span className="ignore" />
                        </button>
                        <ul className={showMenu ? 'activeNav' : ''}>
                            {
                                catalogData.filter(el => el.id > 1).map(el => (
                                    <NavItem key={el.id} href={el.slug} title={el.title} />
                                ))
                            }
                            <li><NavLink to='/catalog/calculator' className="calc-btn">{content.catalogDataTitles[5]}</NavLink></li>
                        </ul>
                        <div className="page-lang">
                            <div className="lang-title">
                                <button className={showLang ? "active" : ""} onClick={() => setShowLang(!showLang)}>
                                    <svg width={9} height={11} viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 5.5L0.75 0.73686V10.2631L9 5.5Z" fill="#fff" />
                                    </svg>
                                    <span>{leng.find(l => l.active).title}</span>
                                </button>
                            </div>
                            {/* {showLang && */}
                                <div style={{
                                    height: showLang ? "66px" : 0
                                }} className="lang-dropw">
                                    {
                                        leng.filter(l => !l.active).map(l => (
                                            <Link onClick={() => {changeLeng(l.leng);  setShowLang(!showLang)}} key={l.leng}>{l.title}</Link>
                                        ))
                                    }
                                </div>
                            {/* // } */}
                        </div>

                    </div>
                </div>
            </div>
        </header>

    )
}

export default Header