import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PaginationNext, PaginationPrev } from '../../svg/svg'
import Fillter from '../Fillter/Fillter';
import ProductItem from '../ProductItem/ProductItem'
import customAxios from '../../axios';
import IMAGES from '../../img';

import 'bootstrap/dist/css/bootstrap.min.css';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { content } from '../../localization';

const BASEURL = process.env.REACT_APP_BASE_URL

const commercialSection = [
    {
        id: "1",
        checked: false,
        value: "Store"
    },
    {
        id: "2",
        checked: false,
        value: "Office"
    },
    {
        id: "3",
        checked: false,
        value: "Production"
    },
    {
        id: "4",
        checked: false,
        value: "Restaurant"
    },
    {
        id: "5",
        checked: false,
        value: "Others"
    },
]
const apartmentSection = [
    {
        id: "1",
        checked: false,
        value: "1"
    },
    {
        id: "2",
        checked: false,
        value: "2"
    },
    {
        id: "3",
        checked: false,
        value: "3"
    },
    {
        id: "4",
        checked: false,
        value: "4+"
    },
]
const landSection = [
    {
        id: "1",
        checked: false,
        value: "Public land area"
    },
    {
        id: "2",
        checked: false,
        value: "Business land area"
    },
    {
        id: "3",
        checked: false,
        value: "Agriculture land area"
    },
]
const housSection = [
    {
        id: "1",
        checked: false,
        value: "House"
    },
    {
        id: "2",
        checked: false,
        value: "Mansion"
    },
    {
        id: "3",
        checked: false,
        value: "Country house"
    },
]

function Catalog({ leng, catalogData, prevURL }) {
    const [currentPage, setCurrentPage] = useState(1)
    const [filterData, setFilterData] = useState({
        price: {
            min: 0,
            max: 10000000
        },

        rooms: [
            {
                id: "1",
                checked: false,
                value: "1"
            },
            {
                id: "2",
                checked: false,
                value: "2"
            },
            {
                id: "3",
                checked: false,
                value: "3"
            },
            {
                id: "4",
                checked: false,
                value: "4+"
            },
        ],

        time: [
            {
                id: "1",
                checked: true,
                value: "Sale"
            },
            {
                id: "2",
                checked: false,
                value: "Short Term Rent"
            },
            {
                id: "3",
                checked: false,
                value: "Daily"
            },
            {
                id: "4",
                checked: false,
                value: "Long Term Rent"
            },
        ],

        zone: [],

        street: [],

        type: [],

        repairCondition: []
    })
    const [products, setProducts] = useState([])
    const [streets, setStreets] = useState([])
    const [active, setActive] = useState(false)

    const { slug } = useParams();

    const { state } = useLocation()

    const navigate = useNavigate()

    const saveInfo = () => {
        sessionStorage.setItem('currentPage', JSON.stringify(currentPage))
        sessionStorage.setItem('filterData', JSON.stringify(filterData))
        sessionStorage.setItem('products', JSON.stringify(products))
        sessionStorage.setItem('streets', JSON.stringify(streets))
    }
    
    useEffect(() => {
        if ((slug === state?.slug || prevURL.includes(slug)) && sessionStorage.getItem('currentPage') && sessionStorage.getItem('filterData') && sessionStorage.getItem('products') && sessionStorage.getItem('streets')) {
            setTimeout(() => {
                setCurrentPage(JSON.parse(sessionStorage.getItem('currentPage')))
                setFilterData(JSON.parse(sessionStorage.getItem('filterData')))
                setProducts(JSON.parse(sessionStorage.getItem('products')))
                setStreets(JSON.parse(sessionStorage.getItem('streets')))
            }, 0)
        }
            
    }, [state, prevURL])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1)
        let url

        switch (slug) {
            case 'land':
                url = `landsview/${leng}`
                setFilterData({
                    ...filterData,
                    time: [...filterData.time.map((el, idx) => ({...el, value: content.timeArr[idx] }))],
                    rooms: [...landSection.map((el, idx) => ({ ...el, value: content.landSectionInfo[idx] }))]
                })
                break;
            case 'commercial':
                url = `commercialsview/${leng}`
                setFilterData({
                    ...filterData,
                    time: [...filterData.time.map((el, idx) => ({ ...el, value: content.timeArr[idx] }))],
                    rooms: [...commercialSection.map((el, idx) => ({...el, value: content.commercialSectionInfo[idx]}))]
                })
                break;
            case 'apartment':
                url = `apartmentsview/${leng}`
                setFilterData({
                    ...filterData,
                    time: [...filterData.time.map((el, idx) => ({ ...el, value: content.timeArr[idx] }))],
                    rooms: apartmentSection
                })
                break;
            case 'hous':
                url = `homesview/${leng}`
                setFilterData({
                    ...filterData,
                    time: [...filterData.time.map((el, idx) => ({ ...el, value: content.timeArr[idx] }))],
                    rooms: [...housSection.map((el, idx) => ({ ...el, value: content.housSectionInfo[idx] }))]
                })
                break;
        }

        customAxios.get(url)
                    .then(res => {
                        // console.log(res.data.length / 21);

                        setProducts([...res.data.map(el => {
                            if (el.is_rent === 1) {
                                return {
                                    ...el,
                                    price: el.rent_price,
                                    catalog: slug,
                                    poster: el.images?.length ? BASEURL + el.images[0].path : IMAGES.placeholder
                                }
                            }
                            return {
                                ...el,
                                price: el.selling_price,
                                catalog: slug,
                                poster: el.images?.length ? BASEURL + el.images[0].path : IMAGES.placeholder
                            }
                        })])
                    })
                    .catch(() => {
                        navigate('/error404')
                    })
    }, [slug, leng])

    const initalProducts = useMemo(() => {
        let data = [...products]
        
        data = data.filter((el) => el.price >= filterData.price.min && el.price <= filterData.price.max)

        let saleType = filterData.time.find(el => el.checked)
        data = data.filter(el => {
            switch (saleType.id) {
                case '1':
                    return el.is_rent === 0
                case '2':
                    return el.rent_type === 'Short Term' || el.rent_type === 'Краткосрочная' || el.rent_type === 'Կարճաժամկետ'
                case '3':
                    return el.rent_type === 'Daily' || el.rent_type === 'Суточная' || el.rent_type === 'Օրավարձ'
                case '4':
                    return el.rent_type === 'Long Term' || el.rent_type === 'Долгосрочная' || el.rent_type === 'Երկարաժամկետ'
            }
        })
        if (filterData.rooms && filterData.rooms.some(item => item.checked)) {
            switch (slug) {
                // let landStatus = filterData.rooms.filter(item => item.checked)
                // data = data.filter(el => landStatus.some(status => status.value === el.status))
                // break;
                case 'land':
                case 'hous':
                case 'commercial':
                    let statuses = filterData.rooms.filter(item => item.checked)
                    data = data.filter(el => statuses.some(status => status.value === el.status))
                    break;
                case 'apartment':
                    let rooms = filterData.rooms.filter(item => item.checked)
                    data = data.filter(el => rooms.some(room => +room.value === el.rooms || room.value === '4+' && el.rooms >= 4))
                    break;
                    // break;
            }
            
        }
        if (filterData.type.length) {
            let types = filterData.type
            data = data.filter(el => types.some(type => type.name === el.building_type))
        }
        if (filterData.repairCondition.length) {
            let repairConditions = filterData.repairCondition
            data = data.filter(el => repairConditions.some(repairCondition => repairCondition.name === el.repair_condition))
        }
        if (filterData.zone.length) {
            if (filterData.street.length) {
                console.log('test');
                data = data.filter(el => filterData.street.some(street => street.name ===el.address))
            }else{
                let streetsData = streets.filter(street => filterData.zone.some(zone => zone.zoneIdes.some(id => id === street.zone)))
                data = data.filter(el => streetsData.some(street => street.name === el.address))
            }
        }

        return data 
    }, [products, filterData, slug, streets])

    return (
        <section className="catalog">
            <div className="catalog__container _container">
                <div className="catalog__title">
                    <h1>{catalogData.find(el => el.slug === slug)?.title}</h1>
                </div>
                <button onClick={() => { setActive(true) }} className="fillter-burger-btn">
                    <span />
                    <span />
                    <span />
                </button>
                <Fillter {...{ filterData, setFilterData, streets, setStreets, leng, active, setActive }} />
                <div className="catalog__right">
                    <div className="catalog__title">
                        <h1>{catalogData.find(el => el.slug === slug)?.title}</h1>
                    </div>

                    <div className="catalog__grid">
                        {
                            initalProducts.slice((currentPage - 1) * 21, currentPage  * 21).map(el => (
                                <ProductItem saveInfo={saveInfo} key={el.id} adreas={el.address} price={el.price} img={el.poster} href={"/product/" + el.catalog + "/" + el.id} />
                            ))
                        }
                    </div>
                    <div className="catalog__paginations">

                    <PaginationControl
                        page={currentPage}
                        between={4}
                        total={initalProducts.length}
                        limit={21}
                        changePage={(page) => {
                            setCurrentPage(page);
                        }}
                        ellipsis={1}
                    />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Catalog