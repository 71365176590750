import React, { useMemo, useRef, useState } from 'react'
import './Calculator.css'
import { content } from '../../localization'
function Calculator() {
  const [isEqual, setIsEqual] = useState(false)
  const [isAMD, setIsAMD] = useState(false)
  const [isShowResult, setIsShowResult] = useState(false)
  const [result, setResult] = useState(null)
  const [table, setTable] = useState([])
  const [showTable, setShowTable] = useState(false)
  const formRef = useRef(null)

  const [price, setPrice] = useState(120000)
  const [deposit, setDeposit] = useState(12000)

  const x = useMemo(() => {
    if (price > deposit) {
      return price - deposit
    }
    return 0
  }, [price, deposit])

  function v1(price, pay, percent, years) {
    let i = parseFloat(percent / 100 / 12);
    let n = parseFloat(years * 12);
    let r = (price - pay) * ((i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1));
    // return Math.round(r)
    return +r.toFixed(1)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setShowTable(false)
    const {
      price: { value: price },
      adPayment: { value: adPayment },
      percent: { value: percent },
      term: { value: term }
    } = formRef.current

    if (!isEqual) {

      setResult({
        monthPay: (v1(price, adPayment, percent, term)).toLocaleString(),
        fullPay: (v1(price, adPayment, percent, term) * 12 * term).toLocaleString(),
        termPay: (v1(price, adPayment, percent, term) * 12 * term - (price - adPayment)).toLocaleString(),
      });

      let balance = price - adPayment

      setTable([
        ...new Array(term * 12).fill(null).map((el, idx) => {
          let percentPart = balance * parseFloat(percent / 100 / 12)
          let mainPart = v1(price, adPayment, percent, term) - percentPart
          balance = balance - mainPart
          // console.log(Math.round(percentPart), Math.round(mainPart), Math.round(balance));
          return {
            month: (idx + 1).toLocaleString(),
            balance: (Math.round(balance)).toLocaleString(),
            percentPart: (Math.round(percentPart)).toLocaleString(),
            mainPart: (Math.round(mainPart)).toLocaleString(),
            monthPay: (v1(price, adPayment, percent, term)).toLocaleString()
          }
        })
      ])
    } else {
      let balance = price - adPayment
      let fullPay = 0
      let termPay = 0
      const newTable = [
        ...new Array(term * 12).fill(null).map((el, idx) => {
          let percentPart = balance * parseFloat(percent / 100 / 12)
          let mainPart = (price - adPayment) / term / 12
          balance = balance - mainPart

          fullPay += mainPart + percentPart
          termPay += percentPart

          return {
            month: (idx + 1).toLocaleString(),
            balance: (Math.round(balance)).toLocaleString(),
            percentPart: (Math.round(percentPart)).toLocaleString(),
            mainPart: (Math.round(mainPart)).toLocaleString(),
            monthPay: (Math.round(mainPart + percentPart)).toLocaleString()
          }
        })
      ]
      setTable(newTable)
      setResult({
        monthPay: null,
        fullPay: fullPay.toLocaleString(),
        termPay: termPay.toLocaleString()
      })
    }

  }


  return (
    <section className='calculator-page'>
      <div className='calculator-page__container'>

        <div className='Calculator'>
          <form ref={formRef} onSubmit={handleSubmit}>
            <h2>{content.calculatorTitle}</h2>
            <div className="form__group swich__group field">
              <h3 onClick={() => setIsEqual(false)} className={!isEqual ? 'active' : ''}>{content.type1}</h3>
              <input checked={isEqual} onChange={() => setIsEqual(!isEqual)} className='swich' type="checkbox" id="switch" /><label className='swich_label' htmlFor="switch"></label>
              <h3 onClick={() => setIsEqual(true)} className={isEqual ? 'active' : ''}>{content.type2}</h3>
            </div>
            <div className="form__group swich__group field">
              <h3 onClick={() => setIsAMD(false)} className={!isAMD ? 'active' : ''}>USD</h3>
              <input checked={isAMD} onChange={() => setIsAMD(!isAMD)} className='swich' type="checkbox" id="switch2" /><label className='swich_label' htmlFor="switch2"></label>
              <h3 onClick={() => setIsAMD(true)} className={isAMD ? 'active' : ''}>AMD</h3>
            </div>
            <div className="form__group field">
              <input value={price} onChange={(e) => setPrice(e.target.value)}  type="number" className="form__field" placeholder="Name" name="price" id="price" required />
              <label htmlFor="price" className="form__label">{content.homePrice}</label>
            </div>
            <div className="form__group field">
              <input value={deposit} onChange={(e) => setDeposit(e.target.value)}  type="number" className="form__field" placeholder="Name" name="adPayment" id="adPayment" required />
              <label htmlFor="adPayment" className="form__label">{content.deposit}</label>
            </div>
            <div className="form__group field">
              <p className="form__field">{x}</p>
              <label className="form__label">{content.loanAmount}</label>
            </div>
            <div className="form__group field">
              <input defaultValue={12} step="0.1" type="number" className="form__field" placeholder="Name" name="percent" id="percent" required />
              <label htmlFor="percent" className="form__label">{content.percent}</label>
            </div>
            <div className="form__group field">
              <input defaultValue={30} type="number" className="form__field" placeholder="Name" name="term" id="term" required />
              <label htmlFor="term" className="form__label">{content.term}</label>
            </div>
            <button>{content.calculatorBtn1}</button>
          </form>
        </div>
      </div>
      {
        result && (
          <div className='calc-modal' onClick={(e) => {
              if(e.target === e.currentTarget) {
                setResult(null); setTable(null)
              }
            }}>
            <div className='calc-modal__container'>
              <button className='clousmodal' onClick={() => {setResult(null); setTable(null);}}>
                <span />
                <span />
              </button>
              <div className='result'>
                {result?.monthPay && <div><span>{content.calculatorResult1}</span> <span>{result?.monthPay}<span>{isAMD ? 'AMD' : 'USD'}</span></span></div>}
                <div><span>{content.calculatorResult2}</span> <span>{result?.fullPay}<span>{isAMD ? 'AMD' : 'USD'}</span></span></div>
                <div><span>{content.calculatorResult3}</span> <span>{result?.termPay}<span>{isAMD ? 'AMD' : 'USD'}</span></span></div>
              </div>
                <div className='table-box'>
                  <div className="table">
                    <div className="table-header">
                      <div className="header__item"><span className="filter__link">{content.th1}</span></div>
                      <div className="header__item"><span className="filter__link filter__link--number">{content.th2}</span></div>
                      <div className="header__item"><span className="filter__link filter__link--number">{content.th3}</span></div>
                      <div className="header__item"><span className="filter__link filter__link--number">{content.th4}</span></div>
                      <div className="header__item"><span className="filter__link filter__link--number">{content.th5}</span></div>
                    </div>
                    <div className="table-content">
                      {
                        table.map(row => (
                          <div className="table-row" key={row.month}>
                            <div className="table-data">{row.month}</div>
                            <div className="table-data">{row.balance}</div>
                            <div className="table-data">{row.percentPart}</div>
                            <div className="table-data">{row.mainPart}</div>
                            <div className="table-data">{row.monthPay}</div>
                          </div>
                        ))
                      }

                    </div>
                  </div>
                </div>
            </div>
          </div>
        )
      }
    </section>

  )
}

export default Calculator